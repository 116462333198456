<template>
  <div class="container">
    <div class="expert">
      <div><van-icon name="manager" />{{ expert.username }}</div>
      <div @click="onLogout">注销</div>
    </div>
    <div>
      <van-search v-model="searchValue" @search="onSearch" @clear="onClear" placeholder="请输入搜索关键词" />
    </div>
    <div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.id" class="community">
            <van-image
              class="community-img"
              :src="item.community.avatar_img || defaultAvatar"
              @click="onPreview(item.community.avatar_img)"
            />
            <div class="info" @click="onSelectAssess(item.id)">
              <div>
                <div class="community-name">{{item.community.name}}</div>
                <div class="property-name">{{ item.community.property.name}}</div>
                <div class="area"><van-icon name="location-o" />
                  {{item.community.district.name}}-
                  {{item.community.block ? item.community.block.name : ''}}-
                  {{item.community.com ? item.community.com.name : '暂无' }}
                </div>
              </div>
              <div class="info-footer">
                <div class="community-date">
                  <span>{{ `${item.year}年度` }}</span>
                  <span>-</span>
                  <span>{{ `第${item.quarterly}次考核` }}</span>
                  <!-- <span>{{ item.start_time }}</span> -->
                </div>
                <div class="community-status">
                  <template v-if="item.status === 0"><span style="color: #969799;">待考核</span></template>
                  <template v-else-if="item.status === 1"><span style="color: #3388ff;">考核中</span></template>
                  <template v-else-if="item.status === 2"><span style="color: #18DAB7;">完成考核</span></template>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { assessList } from '@/api/index'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { Tabbar, TabbarItem, List, PullRefresh, Image as VanImage, ImagePreview, Icon, Search, DropdownMenu, DropdownItem } from 'vant'
export default {
  name: 'Index',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data () {
    return {
      searchValue: '',
      searchParams: '',
      expert: {},
      list: [],
      page: 0,
      loading: false,
      finished: false,
      refreshing: false,
      rows: 10,
      defaultAvatar: 'https://qiniu.0573vote.com/index-default-avatar.jpg'
    }
  },
  methods: {
    onLoad () {
      if (this.refreshing) {
        this.page = 0
        this.list = []
        this.refreshing = false
      }
      this.page++
      const param = {
        search: this.searchParams,
        page: this.page,
        rows: this.rows
      }
      console.log('param', param)
      assessList(param).then((res) => {
        this.expert = res.data.expert
        const list = res.data.data
        if (list.length < this.rows) {
          this.finished = true
        }
        this.loading = false
        this.list.push(...list)
        console.log('this.list', this.list)
      })
    },
    onRefresh () {
      // console.log('ddd')
      this.finished = false
      this.loading = true
      // this.page = 0
      // this.list = []
      // this.refreshing = false
      this.onLoad()
    },
    onPreview (imgUrl) {
      console.log(imgUrl)
      ImagePreview({
        images: [imgUrl],
        showIndex: false,
        closeable: true
      })
    },
    // 选择考核项目
    onSelectAssess (id) {
      console.log(id)
      this.$router.push({ name: 'Module', params: { assessId: id } })
    },
    onSearch () {
      console.log('onSearch')
      this.searchParams = this.searchValue
      this.page = 0
      this.list = []
      this.onRefresh()
    },
    onClear () {
      console.log('onClear')
      this.searchParams = ''
      this.onRefresh()
    },
    onLogout () {
      storage.remove(ACCESS_TOKEN)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }
}
</script>

<style scoped>
.container {
  padding: 10px 20px 0 20px;
  background-color: #fff;
}
.expert {
  /* background-color: #fff; */
  display: flex;
  justify-content: space-between;
}
.community {
  display: flex;
  margin: 0 0 20px 0;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff;
}
.community-name {
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #212121;
  letter-spacing: 6px
}
.property-name {
  font-size: 20px;
  color: #a7a7a7;
  margin: 0 0 8px 0;
}
.area {
  font-size: 16px;
  color: #a7a7a7;
}
img {
  display: block;
}
.info {
  padding: 10px 20px;
  /* border-right: 15px #ffd90096 solid; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1

}
.info-footer {
  display: flex;
  justify-content: space-between;
}
.community-date {
  font-size: 18px;
  color: #a7a7a7;
  display: flex;
}
.community-date > span {
  margin: 0 10px 0 0;
}
.community-status {
  /* color: #18DAB7; */
  font-size: 18px;
}
.community-img {
  width: 180px;
  height: 180px;
}
</style>
